import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react'

export const useFeatureFlag = (flag: string) => {
  const value = useFlag(flag)
  const { flagsReady, flagsError } = useFlagsStatus()

  return {
    value,
    error: flagsError,
    ready: flagsReady,
  }
}
